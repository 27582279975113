import NewRole from "../../GraphqlCalls/Settings/modals/NewRole";
import NewUser from "../../GraphqlCalls/Settings/modals/NewUser";
import NewPropertyGroup from "../../GraphqlCalls/Settings/modals/NewPropertyGroup";
import NewDesign from "../../GraphqlCalls/Design/modals/NewDesign";
import MakeContentDefault from "../../../components/Section/Design/Modals/MakeContentDefault";
import DuplicateContent from "../../../components/Section/Design/Modals/DuplicateContent";
import NewHotspot from "../../GraphqlCalls/Settings/modals/NewHotspot";
import MacExceptions from "../../GraphqlCalls/Settings/modals/MacExcepcionts";
import MenuNavNewItem from "../../GraphqlCalls/MediaLibrary/modals/MenuNavNewItem";
import WidgetItemNew from "../../GraphqlCalls/MediaLibrary/modals/WidgetItemNew";
import i18next from "i18next";
import NewInputGroup from "../../GraphqlCalls/Hotel/modals/NewInputGroup";
import { DEVICE_FUNCTIONS, DEVICE_KINDS } from "../Hotel/Monitor/ListDevicesUtils";
import { capitalizeFirst } from "../Utils";
import NewStaffUser from "hooks/GraphqlCalls/Customers/modals/NewStaffUser";
import i18n from "i18n";

const GetActionModalContent = (actionName, dataType, data, rowContent, t) => {
    const content_t = i18next.getFixedT();
    let modalInputs = [];

    switch (dataType) {
        case "area":
            switch (actionName) {
                case "area-open":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        action: "replace",
                        value: data.name,
                        text: "area-empty-reopen-question",
                    });
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        action: "replace",
                        value: data.name,
                        text: "area-empty-reopen-description",
                    });
                    break;
                case "area-edit-description":
                    modalInputs.push({
                        name: actionName,
                        type: "text-area",
                        text: "enter-area-description",
                        charLimit: 250,
                        selected: data.description,
                    });
                    break;
                case "email-booking-notification":
                    modalInputs.push({
                        name: actionName,
                        type: "multipleInput",
                        text: [content_t("insert-email-booking-notification"), ":"],
                        selectedInputs: rowContent.notificationEmail,
                    });
                    break;
                case "area-temp-close":
                    modalInputs.push({
                        name: `${actionName}-comment`,
                        text: "close-comment",
                        type: "text-area",
                        charLimit: 250,
                    });
                    break;
                case "area-duplicate":
                    modalInputs.push({
                        name: actionName,
                        type: "text",
                        ph: "people-number",
                    });
                    break;
                case "area-move-category":
                    let modalInput = {
                        name: actionName,
                        type: "selectWithSearch",
                        text: "select-move-category",
                        ph: "choose-category",
                        adjust: "w-full",
                        values: [],
                    };
                    modalInputs.push(modalInput);
                    break;
                case "area-erase":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "confirm-area-deletion",
                    });
                    break;
                case "area-see-link":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "companion":
            switch (actionName) {
                case "delete-companion":
                    modalInputs.push({
                        text: "companion-delete",
                        type: "void",
                    });
                    break;
                case "edit-companion":
                    modalInputs.push({
                        type: "editCompanion",
                        data: rowContent?.data ? { ...rowContent.data, isEdition: true } : {},
                    });
                    break;
                default:
                    break;
            }
            break;
        case "gridChannel":
            switch (actionName) {
                case "delete-grid":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: t("grid-confirm-deletion", { grid: data?.name ? data.name : "" }),
                    });
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: t("delete-grid-warning-filter", {
                            numberFilters: rowContent?.numberFilters ? rowContent.numberFilters : 0,
                        }),
                        style: rowContent.numberFilters === 0 ? "opacity-0" : "opacity-1",
                    });
                    break;
                case "add-filter":
                    modalInputs.push({
                        type: "destinations",
                        destinationsValues: rowContent?.destinationsValues ? rowContent.destinationsValues : null,
                        typeDestination: "gridChannelsDestinations",
                        destination: rowContent?.destination ? rowContent.destination : null,
                    });
                    modalInputs.push({
                        type: "text",
                        name: "name-grid-channel",
                        value: rowContent?.name ? rowContent.name : "",
                        cStyle: "hidden",
                    });
                    break;
                case "remove-grid-from-group":
                    modalInputs.push(
                        {
                            name: actionName,
                            type: "void",
                            text: rowContent.t("confirmation-delete-group-grid", { name: rowContent.modal_value }),
                            style: "pb-10",
                            avoidGetFullText: true,
                        },
                        {
                            type: "text",
                            name: "id-group",
                            value: rowContent.id,
                            style: "hidden",
                        }
                    );
                    break;
                default:
                    break;
            }
            break;
        case "staff-member":
            switch (actionName) {
                case "staff-delete":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: i18n.t("delete-user-name-confirmation", { user: rowContent.values.name }),
                    });
                    modalInputs.push({
                        name: "ref",
                        type: "hidden",
                        selected: { id: rowContent.id },
                    });
                    break;
                case "edit-staff-user":
                    modalInputs.push({
                        name: actionName,
                        type: "add-edit-staff-user",
                        values: rowContent.values,
                    });
                    break;
                case "staff-generate-password":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: i18n.t("generate-password-for-the-user", { user: rowContent.values.name }),
                    });
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: i18n.t("new-password-will-replace-sent-to-email", { email: rowContent.values.email }),
                    });
                    modalInputs.push({
                        name: "ref",
                        type: "hidden",
                        selected: { id: rowContent.ref, values: rowContent.values },
                    });
                    break;
                default:
                    break;
            }
            break;
        case "guest-currents":
            switch (actionName) {
                case "guest-delete":
                    modalInputs.push({
                        name: "guest-delete",
                        type: "void",
                        text: "guest-confirm-deletion",
                    });
                    break;
                case "checkout":
                    modalInputs.push({
                        type: "checkout",
                        data: {
                            guestID: rowContent?.guestID,
                            rooms: rowContent.rooms,
                            checkAll: rowContent.checked ? false : true,
                            roomChecked: rowContent.checked,
                        },
                    });
                    break;
                case "full-checkout":
                    modalInputs.push({
                        text: "confirm-full-checkout",
                        type: "void",
                    });

                    rowContent.rooms.map((room) =>
                        modalInputs.push({
                            text: `<div class="float-left z-20  bg-blue-200 text-white pl-2 px-12 p-1 mb-2 rounded">${content_t(
                                "room"
                            )} ${room.number} </div>`,
                            type: "htmltext",
                            style: " pb-3 flex",
                        })
                    );

                    break;
                case "change-room":
                    let rooms = rowContent.rooms;

                    rooms = rooms.sort(function (a, b) {
                        return a.number - b.number;
                    });
                    rooms.map((room) =>
                        modalInputs.push({
                            text: ["room", room.number, ".", "choose-new-room"],
                            name: `room-${room.roomID}`,
                            optionsQuery: `{
                                data: rooms(filter: {free:true}){
                                  info { count }
                                  results { id name  }
                                }       
                            }`,
                            oneSelected: true,
                            ph: "room",
                            cStyle: "w-full",
                            sort: true,
                            type: "selectMultipleWithSearch",
                        })
                    );

                    modalInputs.push({
                        rooms: rooms,
                        guestID: rowContent.guestID,
                        type: "data",
                    });
                    break;

                case "generate-roomcode":
                    modalInputs.push({
                        name: "chromecast-change-pairing",
                        type: "htmltext",
                        replacewords: [rowContent.roomNumber, rowContent.roomNumber],
                        text: "chromecast-change-pairing-text",
                    });
                    modalInputs.push({
                        name: "chromecast-change-pairing-extended",
                        type: "void",
                        text: "chromecast-change-pairing-text-extended",
                        adjust: "max-w-xl",
                    });
                    modalInputs.push({
                        name: "roomid",
                        type: "hidden",
                        selected: rowContent.roomID,
                    });
                    break;
                case "assign-to-group":
                    modalInputs.push({
                        text: ["guest-groups"],
                        name: "guest-groups",
                        optionsQuery: `{
                            data: guestGroups{
                              info { count }
                              results { id name  }
                            }  selected: guests (id:${data.id}){results{guestGroups{id}}}
                        }`,
                        selectedIdsPath: "data.selected.results[0].guestGroups",
                        ph: "select-group",
                        cStyle: "w-full",
                        sort: true,
                        type: "selectMultipleWithSearch",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "guest-upcoming-check-ins":
            switch (actionName) {
                case "delete-pre-check-in":
                    modalInputs.push({
                        text: "delete-pre-check-in-description",
                        type: "void",
                    });
                    break;
                case "resend-pre-check-in":
                    modalInputs.push({
                        text: "resend-pre-check-in-description",
                        type: "void",
                    });
                    break;
                case "execute-checkin":
                    modalInputs.push(
                        {
                            type: "checkIn",
                        },
                        {
                            type: "data",
                            data: { stayBookings: rowContent.info[7].value },
                        }
                    );
                    break;
            }
            break;
        case "room":
            switch (actionName) {
                case "room-group-assign":
                    modalInputs.push({
                        name: "room-groups",
                        optionsQuery: `{
                            data: 
                            roomGroups{
                              info { count }
                              results { id name  }
                            }  selected: rooms(id:${rowContent.id}){results{roomGroups{id}}}
                        }`,
                        selectedIdsPath: "data.selected.results[0].roomGroups",
                        selectPlaceHolder: "select-group",
                        cStyle: "w-full",
                        sort: true,
                        type: "selectMultipleWithSearch",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "topology":
            switch (actionName) {
                case "topology-delete-room":
                    if (rowContent?.recentGuests) {
                        modalInputs.push({
                            name: "topology-delete-room-had-guests",
                            type: "void",
                            text: "topology-delete-room-had-guests",
                        });
                    }
                    modalInputs.push({
                        name: "topology-delete-room",
                        type: "void",
                        text: "topology-delete-room",
                    });
                    break;
                case "topology-delete-floor":
                    modalInputs.push({
                        name: "topology-delete-floor",
                        type: "void",
                        text: "topology-delete-floor",
                    });
                    break;
                case "topology-delete-wing":
                    modalInputs.push({
                        name: "topology-delete-wing",
                        type: "void",
                        text: "topology-delete-wing",
                    });
                    break;
                case "topology-edit-floorname":
                    modalInputs.push({
                        name: actionName,
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-floorname", ":"],
                    });
                    break;
                case "topology-edit-wingname":
                    modalInputs.push({
                        name: actionName,
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-wingname", ":"],
                    });
                    break;
                case "topology-edit-pmsnumber":
                    modalInputs.push({
                        name: actionName,
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-pmsnumber", ":"],
                    });
                    break;
                default:
                    break;
            }
            break;
        case "content":
            let modalContent;
            switch (actionName) {
                case "welcome-edit-name":
                case "content-edit-name":
                    modalInputs.push({
                        name: "screen-name",
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-screenname", ":"],
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "welcome-delete":
                case "delete-welcome-content":
                case "content-delete":
                    modalInputs.push({
                        name: "content-delete",
                        type: "void",
                        text: "screen-delete",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "make-content-default":
                    modalContent = MakeContentDefault(content_t);
                    modalContent.inputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    return modalContent.inputs;
                case "duplicate-in":
                    modalContent = DuplicateContent(content_t);
                    return modalContent.inputs;
                case "save-template":
                    modalInputs.push({
                        type: "htmltext",
                        text: `${content_t("new-template-text").replace(
                            "{{type}}",
                            `<strong>${content_t(rowContent.mutationParams.contentType)}</strong>`
                        )}.`,
                    });
                    modalInputs.push({
                        type: "htmltext",
                        text: `${content_t("write the name of the templates")}.`,
                        style: "first-capital pb-5",
                    });
                    modalInputs.push({
                        type: "translations",
                        customLangs: [{ languageRef: "en" }, { languageRef: "es" }],
                        showDefault: true,
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "content-edit-folder-name":
                    modalInputs.push({
                        name: "name",
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-foldername", ":"],
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "content-folder-delete":
                    modalInputs.push({
                        name: "content-folder-delete",
                        type: "void",
                        text: "folder-delete",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "content-move-to":
                    modalInputs.push({
                        text: ["folder"],
                        name: "folder",
                        optionData: rowContent.contentTree.filter((item) => item.id !== rowContent.id),
                        oneSelected: true,
                        noSelect: true,
                        ph: "move_to",
                        cStyle: "w-full",
                        sort: true,
                        type: "selectMultipleWithSearch",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                default:
                    break;
            }
            break;
        case "user":
            switch (actionName) {
                case "generate-password":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "confirm-pass-reset",
                        action: "tVariables",
                        value: {
                            user: data.name,
                            mail: data.email,
                        },
                    });
                    modalInputs.push({
                        value: rowContent.mutationParams,
                        type: "data",
                    });
                    break;
                case "edit-user":
                    const modalData = NewUser(rowContent.mutationParams, t);
                    modalInputs = modalData[0].inputs;
                    modalInputs.push({
                        value: rowContent.mutationParams,
                        type: "data",
                    });
                    break;
                case "delete-user":
                    modalInputs.push({
                        name: "delete-user",
                        type: "void",
                        text: "delete-user-confirm",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "role":
            switch (actionName) {
                case "edit-role":
                    const modalData = NewRole(rowContent.editData);
                    modalInputs = modalData[0].inputs;
                    break;
                case "duplicate":
                    modalInputs.push({
                        name: "content-delete",
                        type: "void",
                        text: rowContent.isDefault ? "screen-cannot-delete" : "screen-delete",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "delete-role":
                    modalInputs.push({
                        name: "delete-role",
                        type: "void",
                        text: "delete-role-confirm",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "media-library":
            switch (actionName) {
                case "media-library-update-media":
                    modalInputs.push(
                        {
                            name: "files",
                            value: "",
                            type: "file",
                            contentTypes: [
                                {
                                    type: "image",
                                    maxSize: 11,
                                    multiple: false,
                                },
                                {
                                    type: "video",
                                    maxSize: 2048,
                                    multiple: false,
                                },
                            ],
                            triggerAction: "media-library-update-media",
                        },
                        {
                            text: "",
                            name: "file",
                            value: "",
                            type: "text",
                            cStyle: "hidden",
                        },
                        {
                            text: "",
                            name: "fileType",
                            value: "",
                            type: "text",
                            cStyle: "hidden",
                        },
                        {
                            text: "",
                            name: "fileName",
                            value: "",
                            type: "text",
                            cStyle: "hidden",
                        }
                    );
                    break;
                case "media-library-move-content":
                    modalInputs.push({
                        text: ["folder"],
                        name: "folderRef",
                        optionData: rowContent.libraryTreeOptions.filter((item) => item.id !== rowContent.id),
                        oneSelected: true,
                        noSelect: true,
                        ph: "move_to",
                        cStyle: "w-full",
                        sort: true,
                        type: "selectMultipleWithSearch",
                    });
                    break;
                case "media-library-delete-permanently":
                    modalInputs["text"] = (() => {
                        if (rowContent && rowContent.type && rowContent.type === "folder") {
                            return content_t("media-library-delete-file-permanently-text");
                        } else if (rowContent && rowContent.type && rowContent.type === "asset") {
                            return content_t("media-library-delete-asset-permanently-text");
                        } else if (rowContent && rowContent.type && rowContent.type === "video") {
                            return content_t("media-library-delete-video-permanently-text");
                        }
                    })();
                    break;
                case "media-library-download":
                    modalInputs["text"] = content_t("media-library-download-text");
                    break;
                default:
                    break;
            }
            break;
        case "propertyGroup":
            switch (actionName) {
                case "edit-property-group":
                    const modalData = NewPropertyGroup(rowContent.name, rowContent.id);
                    modalInputs = modalData[0].inputs;
                    break;
                case "duplicate-property-group":
                    break;
                case "delete-property-group":
                    break;
                default:
            }
            break;
        case "design":
            switch (actionName) {
                case "duplicate-design":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "duplicate-design-confirm",
                    });
                    modalInputs.push({
                        value: rowContent.mutationParams,
                        type: "data",
                    });
                    break;
                case "edit-design":
                    const modalData = NewDesign(rowContent.mutationParams);
                    modalInputs = modalData[0].inputs;
                    modalInputs.push({
                        value: rowContent.mutationParams,
                        type: "data",
                    });
                    break;
                case "delete-design":
                    modalInputs.push({
                        name: "delete-design",
                        type: "void",
                        text: "delete-design-confirm",
                    });
                    break;
                case "unapply-design":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "unapply-design-confirm",
                    });
                    modalInputs.push({
                        value: rowContent.mutationParams,
                        type: "data",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "template":
            switch (actionName) {
                case "delete-template":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "delete-template-confirm",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                case "edit-template-name":
                    modalInputs.push({
                        type: "htmltext",
                        text: `${content_t("write the name of the templates")}.`,
                        style: "first-capital pb-5",
                    });

                    modalInputs.push({
                        type: "translations",
                        customLangs: [
                            {
                                languageRef: "en",
                            },
                            {
                                languageRef: "es",
                            },
                        ],
                        textId: rowContent.mutationParams.nameTID,
                        parentType: "TEMPLATES",
                        showDefault: true,
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });

                    break;

                default:
                    break;
            }
            break;
        case "message":
            switch (actionName) {
                case "delete-message":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "delete-msg-confirm",
                    });
                    break;
                case "delete-permanently":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "delete-msg-permanently-confirm",
                    });
                    break;
                case "save-as-template":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "save-template-confirm",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "appearance":
            switch (actionName) {
                case "appearance-edit-name":
                    modalInputs.push({
                        name: "appearance-name",
                        type: "text",
                        value: rowContent.modal_value,
                        focus: true,
                        text: ["write-appearance-name", ":"],
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;

                case "delete-appearance":
                    modalInputs.push({
                        name: actionName,
                        type: "void",
                        text: "delete-appearance-confirm",
                    });
                    modalInputs.push({
                        type: "data",
                        value: rowContent.mutationParams,
                    });
                    break;
                default:
                    break;
            }
            break;
        case "hotspot":
            switch (actionName) {
                case "delete-hotspot":
                    modalInputs.push({
                        name: "delete-hotspot",
                        type: "void",
                        text: "delete-hotspot-confirmation",
                    });
                    break;
                case "edit-hotspot-name":
                    const modalDataN = NewHotspot(rowContent, actionName);
                    modalInputs = modalDataN[0].inputs;
                    break;
                case "edit-hotspot-link":
                    const modalDataL = NewHotspot(rowContent, actionName);
                    modalInputs = modalDataL[0].inputs;
                    break;
                case "handle-mac-exceptions":
                    const modalDataMacs = MacExceptions(rowContent);
                    modalInputs = modalDataMacs[0].inputs;
                    break;
                default:
                    break;
            }
            break;
        case "alarm":
            switch (actionName) {
                case "edit-room-alarm":
                case "edit-alarm":
                    modalInputs.push(
                        {
                            text: "edit-alarm",
                            type: "editAlarm",
                        },
                        {
                            type: "data",
                            value: rowContent,
                        }
                    );
                    break;
                case "delete-alarm":
                    modalInputs.push({
                        text: "alarm-delete-warnign",
                        type: "void",
                        action: "tVariables",
                        value: {
                            isFromGuest:
                                rowContent.isFromGuest ||
                                (rowContent["modal_value"] && rowContent["modal_value"].isFromGuest)
                                    ? content_t("by-guest")
                                    : "",
                            guestName: rowContent.info
                                ? rowContent.info[0].value
                                : rowContent.alarms
                                ? rowContent.alarms[0].info[0].value
                                : "",
                            date: rowContent.info
                                ? rowContent.info[3].value
                                : rowContent.alarms
                                ? rowContent.alarms[0].info[3].value
                                : "",
                            time: rowContent.info
                                ? rowContent.info[2].value
                                : rowContent.alarms
                                ? rowContent.alarms[0].info[2].value
                                : "",
                        },
                    });
                    break;
                case "delete-room-alarm":
                    modalInputs.push({
                        text: "delete-all-alarms",
                        type: "void",
                        action: "tVariables",
                        value: {
                            roomName: rowContent.roomName,
                        },
                    });
                    break;

                default:
                    break;
            }
            break;
        case "widget-nav":
            switch (actionName) {
                case "edit-item-widget-nav":
                    const modalMenuNavData = MenuNavNewItem(
                        rowContent.t,
                        rowContent.widgetID,
                        rowContent,
                        rowContent.lang
                    );
                    modalInputs = modalMenuNavData.inputs;
                    break;
                case "delete-item-widget-nav":
                    modalInputs.push(
                        {
                            text: "delete-item-nav-confirm",
                            type: "void",
                        },
                        {
                            text: "delete-item-nav-confirm",
                            name: "save-item-id",
                            type: "text",
                            value:
                                rowContent && rowContent.data && rowContent.data.itemId ? rowContent.data.itemId : "",
                            style: "hidden",
                            cStyle: "hidden",
                        }
                    );
                    break;
                default:
                    break;
            }
            break;
        case "widget-slider":
            switch (actionName) {
                case "edit-item-widget-item":
                    const modalMenuNavData = WidgetItemNew(
                        rowContent.t,
                        rowContent.widgetID,
                        rowContent,
                        rowContent.lang
                    );
                    modalInputs = modalMenuNavData.inputs;
                    break;
                case "delete-item-widget-item":
                    modalInputs.push(
                        {
                            text: "delete-item-nav-confirm",
                            type: "void",
                        },
                        {
                            text: "delete-item-nav-confirm",
                            name: "save-item-id",
                            type: "text",
                            value:
                                rowContent && rowContent.data && rowContent.data.itemId ? rowContent.data.itemId : "",
                            style: "hidden",
                            cStyle: "hidden",
                        }
                    );
                    break;
                default:
                    break;
            }
            break;
        case "wifi-licenses":
            switch (actionName) {
                case "reset-wifi-licenses":
                    modalInputs.push({
                        text: rowContent.t("reset-wifi-licenses-modal-text"),
                        type: "htmltext",
                    });
                    break;

                default:
                    break;
            }
            break;
        case "categorySales":
            switch (actionName) {
                case "set-as-unavailable-category":
                    modalInputs.push({
                        text: "confirm-set-as-unavailable-category",
                        type: "void",
                    });
                    break;
                case "delete-category":
                    modalInputs.push({
                        text: rowContent.t("category-x-delete", { category: rowContent.name }),
                        type: "htmltext",
                    });
                    if (rowContent.childrenCount > 0) {
                        modalInputs.push({
                            text: rowContent.t("categories-count", {
                                count: rowContent.childrenCount,
                                text: rowContent.t("subcategories"),
                            }),
                            type: "htmltext",
                        });
                    }

                    break;
                case "set-as-available-category":
                    modalInputs.push({
                        text: "confirm-set-as-available-category",
                        type: "void",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "product":
            switch (actionName) {
                case "set-as-unavailable-product":
                    modalInputs.push({
                        text: "confirm-set-as-unavailable-product",
                        type: "void",
                    });
                    break;
                case "delete-product":
                    modalInputs.push({
                        text: rowContent.t("product-x-delete", { product: rowContent.name }),
                        type: "htmltext",
                    });
                    break;
                case "set-as-available-product":
                    modalInputs.push({
                        text: "confirm-set-as-available-product",
                        type: "void",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "inputGroup":
            switch (actionName) {
                case "set-as-unavailable-product":
                    modalInputs.push({
                        text: "confirm-set-as-unavailable-product",
                        type: "void",
                    });
                    break;
                case "edit-input-group":
                    const inputInitData = {
                        "input-group-name": rowContent.name,
                        editionData: rowContent,
                    };
                    modalInputs = NewInputGroup(inputInitData, true).inputs;
                    break;
                case "delete-tvInput":
                    modalInputs.push({
                        text: rowContent.t("input-group-delete").replace("%0", rowContent.name),
                        type: "htmltext",
                    });
                    break;
                case "set-as-available-product":
                    modalInputs.push({
                        text: "confirm-set-as-available-product",
                        type: "void",
                    });
                    break;
                case "manage-tvs-assigned":
                    modalInputs.push({
                        text: `${t("assing-tvs-to-input")}`,
                        type: "assignedTvsManagement",
                        tvsAssigned: rowContent.tvsAssigned,
                        isDefault: false,
                    });
                    break;
                default:
                    break;
            }
            break;
        case "corporateChannel":
            switch (actionName) {
                case "delete-corporate-channel":
                    modalInputs.push({
                        type: "void",
                        text: rowContent.t("delete-channel-confirmation-name", { name: rowContent.name }),
                    });
                    if (rowContent.usedInProjectsNum > 0) {
                        modalInputs.push({
                            type: "void",
                            text: rowContent.t("delete-corporate-channel-warning", {
                                number: rowContent.usedInProjectsNum,
                            }),
                        });
                    }
                    break;
                case "delete-video-from-corporate-channel":
                    modalInputs.push({
                        type: "void",
                        text: capitalizeFirst(rowContent.t("media-library-delete-video-permanently-text")),
                        selected: rowContent.channel_info,
                        name: "delete-video-from-corporate-channel",
                    });
                    break;
                case "corporate-channel-video-details":
                    modalInputs.push({
                        type: "videoDetails",
                        data: rowContent.data,
                    });
                    break;
                default:
                    break;
            }
            break;
        case "channel":
            switch (actionName) {
                case "delete-channel":
                    modalInputs.push({
                        type: "void",
                        text: i18next.t("delete-channel-confirmation-name", { name: rowContent.name }),
                    });

                    const gridsLength = rowContent?.grids?.length || 0;
                    const groupsLength = rowContent?.groups?.length || 0;

                    if (gridsLength > 0 || groupsLength > 0) {
                        let type = "void";
                        let textKey = "delete-channel-warning-";
                        let translationParams = {};

                        if (gridsLength > 0 && groupsLength === 0) {
                            textKey += "grid";
                            translationParams = {
                                grids: capitalizeFirst(gridsLength > 1 ? i18next.t("grids_") : i18next.t("grid")),
                                numberGrids: gridsLength,
                            };
                        } else if (gridsLength === 0 && groupsLength > 0) {
                            textKey += "group";
                            translationParams = {
                                groups: capitalizeFirst(groupsLength > 1 ? i18next.t("groups") : i18next.t("group")),
                                numberGroups: groupsLength,
                            };
                        } else {
                            textKey += "grid-group";
                            translationParams = {
                                groups: capitalizeFirst(groupsLength > 1 ? i18next.t("groups") : i18next.t("group")),
                                numberGroups: groupsLength,
                                grids: capitalizeFirst(gridsLength > 1 ? i18next.t("grids_") : i18next.t("grid")),
                                numberGrids: gridsLength,
                            };
                        }

                        modalInputs.push({
                            type,
                            text: i18next.t(textKey, translationParams),
                        });
                    }

                    break;
                case "edit-move-channel-group":
                    modalInputs.push(
                        {
                            type: "void",
                            text: rowContent.t("position-when-channel-appear-in-the-group", {
                                channel: rowContent.modal_value,
                            }),
                            name: "edit-move-channel-group",
                            selected: rowContent.channel_info,
                        },
                        {
                            type: "number",
                            style: "-mt-4 pb-8",
                            name: "edit-move-channel-group-position",
                            currentValue: rowContent.current_value,
                            min: 1,
                            max: rowContent.max_value,
                        },
                        {
                            type: "htmltext",
                            text: rowContent.t("please-note-this-may-alter-position-of-some-channe"),
                        }
                    );
                    break;
                case "delete-channel-group":
                    modalInputs.push({
                        type: "void",
                        text: rowContent.t("delete-channel-from-group-confirmation", {
                            group: rowContent.name_channel_group,
                            channel: rowContent.modal_value,
                        }),
                        name: "delete-channel-group",
                        selected: rowContent.channel_info,
                        style: "pb-10",
                    });
                    break;
                default:
                    break;
            }
            break;
        case "device":
            switch (actionName) {
                case "device-deauthorise":
                    const isTvCast = rowContent.function === DEVICE_FUNCTIONS.tvAndcast.value;
                    modalInputs.push(
                        {
                            type: "void",
                            text: t("device-deauthorise-msg-confirmation", { name: rowContent.name }),
                            style: "pb-1",
                        },
                        {
                            type: "void",
                            text: t("devices-will-be-deauthorised-property"),
                            style: isTvCast ? " pb-5" : " pb-14",
                        }
                    );
                    if (isTvCast) {
                        modalInputs.push({
                            type: "void",
                            text: t("device-assigned-as-tv-unassigned-as-well"),
                            style: "pb-8",
                        });
                    }
                    break;
                case "device-launch-settings-screen":
                    modalInputs.push({
                        style: "pb-4",
                        text: "chromecast-launch-settings-screen-text",
                        type: "void",
                    });
                    modalInputs.push({
                        adjust: "max-w-xl",
                        style: "pb-4",
                        text: "launch-settings-screen-warning",
                        type: "void",
                    });
                    break;
                case "device-launch-system-settings-screen":
                    modalInputs.push({
                        style: "pb-4",
                        text: "chromecast-launch-settings-screen-text",
                        type: "void",
                    });
                    modalInputs.push({
                        adjust: "max-w-xl",
                        style: "pb-4",
                        text: "launch-system-settings-screen-warning",
                        type: "void",
                    });
                    break;
                case "device-pairing-remote-control":
                    modalInputs.push({
                        type: "void",
                        text: "chromecast-pairing-remote-control-text",
                    });
                    modalInputs.push({
                        type: "void",
                        text: "chromecast-pairing-remote-control-text-extended",
                    });
                    break;
                case "device-detach-from-property":
                    modalInputs.push({
                        type: "void",
                        text: t("detach-device-confirmation-text", { name: rowContent.name }),
                    });
                    modalInputs.push({
                        type: "void",
                        text: "device-will-be-authorised-property",
                    });
                    break;
                case "device-delete-device":
                    if (rowContent.function === DEVICE_FUNCTIONS.tvAndcast.value) {
                        modalInputs.push({
                            type: "void",
                            text: t("cc-all-assigned"),
                        });
                    }
                    modalInputs.push({
                        type: "void",
                        text:
                            rowContent.roomNumber && rowContent.roomNumber > 0
                                ? t("delete-device-in-room-confirm", {
                                      device: rowContent.name,
                                      number: rowContent.roomNumber,
                                  })
                                : t("delete-device-in-room-confirm-without-room", {
                                      device: rowContent.name,
                                  }),
                        style: " pb-8",
                    });
                    break;
                case "device-hide-status":
                    modalInputs.push({
                        action: "replace",
                        type: "void",
                        value: rowContent.name,
                        text: "chromecast-disable-text",
                    });
                    break;
                case "device-reload-interface":
                    modalInputs.push({
                        action: "replace",
                        type: "void",
                        value: rowContent.name,
                        text: "device-reload-interface-text",
                    });
                    break;
                case "device-restart":
                    modalInputs.push(
                        {
                            action: "replace",
                            type: "htmltext",
                            replacewords: [rowContent.name, rowContent.roomNumber],
                            text: "device-restart-text",
                        },
                        {
                            type: "void",
                            text: "device-restart-text-extended",
                        }
                    );
                    break;
                case "device-move-room":
                    modalInputs.push(
                        {
                            type: "void",
                            text: ["select-roomtv-text", " ", rowContent.name],
                        },
                        {
                            name: "roomtvId",
                            type: "selectMultipleWithSearch",
                            optionsQuery: `
                            {
                                data: roomtvsWithoutDeviceByFunction (kind:${rowContent.kind} function:${
                                rowContent.deviceFunctionData ? rowContent.deviceFunctionData.enumValue : ""
                            }){
                                    roomID
                                    roomName
                                    roomNumber
                                    roomTVID
                                    roomTVName
                                }
                            }`,
                            oneSelected: true,
                            noSelect: true,
                            cStyle: "w-12/12",
                            idLabel: "roomTVID",
                            nameLabel: "roomTVName",
                            selectPlaceHolder: "select",
                        },
                        {
                            type: "htmltext",
                            text: [
                                "device-function",
                                ":",
                                rowContent.deviceFunctionData ? rowContent.deviceFunctionData.label : ``,
                            ],
                            tooltip: "chromecast-assign-stb-tooltip",
                            translate: true,
                            cStyle: "flex items-center",
                        }
                    );
                    break;
                case "device-unassign":
                    modalInputs.push({
                        name: "device-unassign",
                        type: "htmltext",
                        replacewords: [rowContent.name, rowContent.roomNumber],
                        text: "device-unassign-text",
                    });
                    modalInputs.push({
                        name: "device-unassign-text-extended",
                        type: "void",
                        text: "device-unassign-text-extended",
                        adjust: "max-w-xl",
                    });
                    break;
                case "device-authorise":
                    modalInputs.push({
                        name: "device-authorise",
                        type: "htmltext",
                        replacewords: [rowContent.name],
                        text: "device-authorise-text",
                    });
                    break;
                case "device-uninstall":
                    if (rowContent.roomNumber && rowContent.roomNumber > 0) {
                        modalInputs.push({
                            name: "tv-uninstall-text",
                            type: "htmltext",
                            text: "tv-uninstall-text",
                            replacewords: [rowContent.roomTVName, rowContent.roomNumber],
                            style: "pb-16",
                        });
                    } else {
                        modalInputs.push({
                            name: "device-uninstall-text-no-number",
                            type: "htmltext",
                            text: "device-uninstall-text-no-number",
                            style: "pb-16",
                        });
                    }

                    break;
                case "device-show-status":
                    modalInputs.push({
                        name: "device-show-status-confirmation",
                        type: "void",
                        text: t("device-show-status-confirmation", { device: rowContent.name }),
                        adjust: "pb-10",
                    });
                    break;
                case "device-edit-name":
                    modalInputs.push({
                        name: "device_edit_name",
                        type: "text",
                        ph: "name",
                        selected: rowContent.name,
                        value: rowContent.name,
                        text: "device-edit-name-text",
                    });
                    modalInputs.push({
                        name: "ref",
                        type: "hidden",
                        selected: rowContent.ref,
                    });
                    break;
                case "device-assign":
                    modalInputs.push({
                        name: "assign-device-id",
                        type: "assign-device",
                        data: { ...rowContent, onlyAssign: true },
                    });

                    break;
                case "device-authorise-and-assign":
                    modalInputs.push({
                        name: "assign-device-id",
                        type: "assign-device",
                        data: { ...rowContent, onlyAssign: false },
                    });
                    break;
                case "device-change-device-function":
                    modalInputs.push({
                        name: "device_function",
                        type: "changeDeviceFunction",
                        data: { ...rowContent },
                    });
                    break;
                case "device-power-off":
                    modalInputs.push({
                        name: "tv-power-off-text",
                        type: "htmltext",
                        text: "tv-power-off-text",
                        replacewords: [rowContent.roomTVName, rowContent.roomNumber],
                        adjust: "max-w-xl",
                    });
                    break;
                case "device-power-on":
                    modalInputs.push({
                        name: "tv-power-on-text",
                        type: "htmltext",
                        text: "tv-power-on-text",
                        replacewords: [rowContent.roomTVName, rowContent.roomNumber],
                        adjust: "max-w-xl",
                    });
                    break;
                case "device-edit-tv-inputs":
                    modalInputs.push({
                        name: "edit-tv-input-text",
                        type: "htmltext",
                        text: t("edit-tv-input-text", {
                            room: t("{{capitalize, capitalize}}", {
                                capitalize: t("room-x", { number: rowContent.roomNumber }),
                            }),
                            location: rowContent.roomTVName,
                        }),
                        adjust: "max-w-xl",
                    });

                    modalInputs.push({
                        type: "inputGroupDetailWithSelection",
                        tvInputs: rowContent.tvInputs,
                        tvInputID: rowContent.tvInputID,
                        roomTVID: rowContent.roomTVID,
                    });
                    break;
                case "device-factory-reset":
                    modalInputs.push({
                        name: "chromecast-factory-reset",
                        type: "htmltext",
                        replacewords: [rowContent.name],
                        text: "chromecast-factory-reset-text",
                    });
                    modalInputs.push({
                        name: "chromecast-factory-reset-extended",
                        type: "void",
                        text: "chromecast-factory-reset-text-extended",
                        adjust: "max-w-xl",
                    });
                    break;
                default:
                    break;
            }

            break;
        default:
            break;
    }
    return modalInputs;
};

export default GetActionModalContent;
