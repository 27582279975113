import React from "react";
import {
    DEVICE_FUNCTIONS,
    DEVICE_STATUS,
    DEVICE_TYPES,
    getInputGroup,
} from "hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import { DeviceFunction, Devices } from "../Monitoring/models/Devices";
import {
    Assign,
    AssignGGTV,
    Authorize,
    AuthorizeAndAssign,
    ChangeDeviceFunction,
    Delete,
    EditName,
    HideStatus,
    MonitorActions,
    MoveRoom,
    Reload,
    ShowStatus,
    TurnOff,
    TurnON,
} from "components/Section/Hotel/Monitor/MonitorComponents/ActionsComponents";
import { Session } from "hooks/Utils/Session";

export const arrangeData = (data, t, castEnabled, permissions, open, close, refetch) => {
    const devices = [];
    const tvInputs = data?.tvInputs?.results ? data?.tvInputs?.results : [];
    // eslint-disable-next-line
    data?.devices?.results?.map((device) => {
        const inputGroupData = getInputGroup(device?.tvInputID, tvInputs);
        devices.push(
            Devices(
                {
                    device: device,
                    inputGroupData: inputGroupData,
                    tvInputs: tvInputs,
                    castEnabled: castEnabled,
                    open,
                    close,
                    refetch,
                },
                t,
                permissions
            )
        );
    });
    return devices;
};

export const arrangeFilterData = (data, t) => {
    const { deviceFuntions, deviceModels, floors, rooms } = data;
    let response = {
        deviceModels: [],
        floors: [],
        deviceFunctions: [],
        status: [],
    };

    if (deviceFuntions) {
        Object.keys(deviceFuntions).forEach((deviceFunctionKey) => {
            if (deviceFuntions[deviceFunctionKey]) {
                return Object.keys(DEVICE_FUNCTIONS).forEach((actionKey) => {
                    if (DEVICE_FUNCTIONS[actionKey].typeValue === deviceFunctionKey) {
                        response.deviceFunctions.push({
                            value: DEVICE_FUNCTIONS[actionKey].value,
                            id: DEVICE_FUNCTIONS[actionKey].enumValue,
                            label: t(DEVICE_FUNCTIONS[actionKey].label),
                        });
                    }
                });
            }
        });
    }
    if (deviceModels?.results?.length > 0) {
        // eslint-disable-next-line
        deviceModels?.results?.map((deviceModel) => {
            let models = deviceModel.model;
            if (models?.length > 0) {
                response.deviceModels.push({
                    id: deviceModel.type,
                    label: deviceModel.type,
                    options: models.map((model) => {
                        return {
                            id: model,
                            label: model,
                            value: model,
                        };
                    }),
                });
            }
        });
    }
    if (floors?.results.length > 0) {
        let floorsArray = [];
        // eslint-disable-next-line
        floors?.results?.forEach((floor) => {
            const { id, name, floors: results } = floor;
            floorsArray.push({
                id,
                label: name,
                options: results?.map((floor) => ({
                    id: floor.id,
                    label: floor.name,
                    value: floor.id,
                })),
            });
        });
        // floorsArray = _.orderBy(floorsArray, ["optGroup", "value"], ["asc", "asc"]);
        response.floors = floorsArray;
    }
    Object.keys(DEVICE_STATUS).map((actionKey) => {
        if (!DEVICE_STATUS[actionKey].notFilterable) {
            response.status.push({
                value: DEVICE_STATUS[actionKey].label,
                id: DEVICE_STATUS[actionKey].value,
                label: t(DEVICE_STATUS[actionKey].label),
            });
        }
        return null;
    });
    return response;
};

export const renderIcon = (deviceFunction) => {
    switch (deviceFunction) {
        case DEVICE_FUNCTIONS.tv.value:
            return <DeviceFunction deviceFunction={"tv"} />;
        case DEVICE_FUNCTIONS.tvAndcast.value:
            return <DeviceFunction deviceFunction={"tvAndcast"} />;
        case DEVICE_FUNCTIONS.cast.value:
            return <DeviceFunction deviceFunction={"cast"} />;
    }
};

export const sortByFunction = (deviceFunction) => {
    switch (deviceFunction) {
        case DEVICE_FUNCTIONS.tv.value:
            return DEVICE_FUNCTIONS.tv.value;
        case DEVICE_FUNCTIONS.tvAndcast.value:
            return DEVICE_FUNCTIONS.tvAndcast.value;
        case DEVICE_FUNCTIONS.cast.value:
            return DEVICE_FUNCTIONS.cast.value;
    }
};

export const getAvailableActions = (device, t, open, refetch, hasCast) => {
    const { warning, name, roomNumber, ref, mac, type, model, roomTVName, kind, function: deviceFunction } = device;
    const superUser = Session.getSessionProp("superUser");

    let actions = [];

    const addAction = (id, label, component, dangerousHTML) => {
        actions.push({
            id,
            label: dangerousHTML ? <p dangerouslySetInnerHTML={{ __html: t(label) }}></p> : t(label),
            onClick: () => {
                open(component);
            },
        });
    };

    const showStatus = [
        {
            id: "show-status",
            label: "chromecast-enable",
            component: <ShowStatus name={name} reference={ref} refetch={refetch} />,
        },
    ];

    const desauthorize = [
        superUser && {
            id: "deauthorise",
            label: "deauthorise",
            component: (
                <MonitorActions
                    id="desauthorize"
                    title="deauthorise"
                    buttonText="deauthorise"
                    mutation="deauthorizeDevice"
                    mutationProps={ref}
                    primaryText="deauthorise-device"
                    secondaryText="devices-will-be-deauthorised-property"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const assignGGTV = [
        {
            id: "assign-ggtv",
            label: "assign",
            component: (
                <AssignGGTV name={name} reference={ref} refetch={refetch} deviceFunction={deviceFunction} type={type} />
            ),
        },
    ];

    const hideStatus = [
        {
            id: "hide-status",
            label: "chromecast-disable",
            component: <HideStatus name={name} reference={ref} refetch={refetch} />,
        },
    ];

    const reload = [
        {
            id: "reload-interface",
            label: "tv-reload",
            component: <Reload reference={ref} name={name} kind={kind} refetch={refetch} />,
        },
    ];

    const restartDevice = [
        {
            id: "restart-device",
            label: "chromecast-reboot",
            component: (
                <MonitorActions
                    id="restart-device"
                    title="chromecast-reboot"
                    buttonText="chromecast-reboot"
                    mutation="restartDevice"
                    mutationProps={ref}
                    dangerousHTML="devices-restart-text"
                    secondaryText="device-restart-text-extended"
                    textVariables={{ name, roomNumber }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const commonActions = [
        {
            id: "unassign-cc",
            label: hasCast ? "unassign" : "uninstall",
            component: (
                <MonitorActions
                    id="unassign"
                    title={hasCast ? "unassign" : "uninstall"}
                    buttonText={hasCast ? "unassign" : "uninstall"}
                    mutation={hasCast ? "unassign" : "uninstallTVDevice"}
                    mutationProps={ref}
                    primaryText="devices-unassign-text"
                    secondaryText="chromecast-unassign-text-extended"
                    textVariables={{ name, roomNumber }}
                    refetch={refetch}
                />
            ),
        },
        {
            id: "move-room",
            label: "move-room",
            component: (
                <MoveRoom
                    name={name}
                    reference={ref}
                    roomNumber={roomNumber}
                    refetch={refetch}
                    deviceFunction={deviceFunction}
                />
            ),
        },
        {
            id: "edit-name",
            label: "edit-name",
            component: <EditName name={name} reference={ref} refetch={refetch} />,
        },
    ];

    const unauthorizedDevices = [
        superUser && {
            id: "authorize-cc",
            label: "authorise",
            component: <Authorize name={name} type={type} mac={mac} model={model} reference={ref} refetch={refetch} />,
        },
        { id: "delete-cc", label: "delete", component: <Delete name={name} reference={ref} refetch={refetch} /> },
    ];

    const unassignedDevices = [
        {
            id: "edit-name",
            label: "edit-name",
            component: <EditName name={name} reference={ref} refetch={refetch} />,
        },
        {
            id: "detach-from-property",
            label: "detach-from-property",
            component: (
                <MonitorActions
                    id="detach"
                    title="detach-from-property"
                    buttonText="detach"
                    mutation="detachFromProperty"
                    mutationProps={ref}
                    dangerousHTML="detach-device-confirmation-text"
                    secondaryText="device-will-be-authorised-property"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
        superUser && {
            id: "deauthorise",
            label: "deauthorise",
            component: (
                <MonitorActions
                    id="desauthorize"
                    title="deauthorise"
                    buttonText="deauthorise"
                    mutation="deauthorizeDevice"
                    mutationProps={ref}
                    primaryText="deauthorise-device"
                    secondaryText="devices-will-be-deauthorised-property"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const commonTVActions = [
        {
            id: "move-room",
            label: "move-room",
            component: (
                <MoveRoom
                    name={name}
                    reference={ref}
                    roomNumber={roomNumber}
                    refetch={refetch}
                    deviceFunction={deviceFunction}
                />
            ),
        },
        {
            id: "uninstall",
            label: "uninstall",
            component: (
                <MonitorActions
                    id="uninstall"
                    title="uninstall"
                    buttonText="uninstall"
                    mutation="uninstallTVDevice"
                    mutationProps={ref}
                    dangerousHTML="uninstall-tv"
                    textVariables={{ name, roomTVName, roomNumber }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const onlineChromecastStatusActions = [
        ...commonActions,
        ...restartDevice,
        ...hideStatus,
        superUser && {
            id: "factory-reset",
            label: "factory-reset",
            component: (
                <MonitorActions
                    id="factory-reset"
                    title="factory-reset"
                    buttonText="restore"
                    mutation="factoryReset"
                    mutationProps={ref}
                    dangerousHTML="chromecasts-factory-reset-text"
                    secondaryText="chromecast-factory-reset-text-extended"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
        superUser && {
            id: "deauthorise",
            label: "deauthorise",
            component: (
                <MonitorActions
                    id="desauthorize"
                    title="deauthorise"
                    buttonText="deauthorise"
                    mutation="deauthorizeDevice"
                    mutationProps={ref}
                    primaryText="deauthorise-device"
                    secondaryText="devices-will-be-deauthorised-property"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const commonActionsGGTV = [
        ...commonActions,
        hasCast && {
            id: "change-device-function",
            label: "change-device-function",
            component: (
                <ChangeDeviceFunction name={name} reference={ref} refetch={refetch} deviceFunction={deviceFunction} />
            ),
        },
        superUser && {
            id: "deauthorise",
            label: "deauthorise",
            component: (
                <MonitorActions
                    id="desauthorize"
                    title="deauthorise"
                    buttonText="deauthorise"
                    mutation="deauthorizeDevice"
                    mutationProps={ref}
                    primaryText="deauthorise-device"
                    secondaryText="devices-will-be-deauthorised-property"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const onlineGoogleTVActions = [
        ...commonActionsGGTV,
        ...reload,
        ...restartDevice,
        ...hideStatus,
        {
            id: "pairing-remote-control",
            label: "chromecast-pairing-remote-control",
            component: (
                <MonitorActions
                    id="chromecast-pairing-remote-control"
                    title="chromecast-pairing-remote-control"
                    buttonText="start"
                    mutation="pairingRemoteControl"
                    mutationProps={ref}
                    primaryText="chromecast-pairing-remote-control-text"
                    secondaryText="chromecast-pairing-remote-control-text-extended"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
        superUser && {
            id: "launch-settings-screen",
            label: "launch-settings-screen",
            component: (
                <MonitorActions
                    id="launch-settings-screen"
                    title="launch-settings-screen"
                    buttonText="launch"
                    mutation="launchSettingsScreen"
                    mutationProps={ref}
                    primaryText="chromecast-launch-settings-screen-text"
                    icon={"warning"}
                    iconText="take-few-minutes"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
        superUser && {
            id: "launch-system-settings-screen",
            label: "launch-system-settings-screen-not-recommended",
            dangerousHTML: true,
            component: (
                <MonitorActions
                    id="launch-system-settings-screen"
                    title="launch-system-settings-screen"
                    buttonText="launch"
                    mutation="launchSystemSettingsScreen"
                    mutationProps={ref}
                    primaryText="chromecast-launch-system-settings-screen-text"
                    dangerousHTML="launch-system-settings-screen-warning"
                    textVariables={{ name }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const chromecastStatusActions = {
        [DEVICE_STATUS.online.label]: onlineChromecastStatusActions,
        [DEVICE_STATUS.lowSignal.value]: onlineChromecastStatusActions,
        [DEVICE_STATUS.offline.label]: [...commonActions, ...hideStatus, ...desauthorize],
        [DEVICE_STATUS.hiddenStatus.value]: [...showStatus, ...commonActions, ...desauthorize],
        [DEVICE_STATUS.unauthorized.label]: [
            superUser && {
                id: "authorise-and-assign",
                label: "authorise-and-assign",
                component: (
                    <AuthorizeAndAssign
                        name={name}
                        type={type}
                        mac={mac}
                        model={model}
                        reference={ref}
                        refetch={refetch}
                        deviceType={DEVICE_TYPES.chromecast.value}
                        deviceFunction={deviceFunction}
                    />
                ),
            },
            ...unauthorizedDevices,
        ],
        [DEVICE_STATUS.unavailable.label]: [...commonActions, ...desauthorize],
        [DEVICE_STATUS.unassigned.label]: [
            { id: "assign-cc", label: "assign", component: <Assign name={name} reference={ref} refetch={refetch} /> },
            ...unassignedDevices,
        ],
    };

    const tvStatusActions =
        DEVICE_TYPES.philips.value !== type
            ? {
                  [DEVICE_STATUS.online.label]: [
                      {
                          id: "turn-off",
                          label: "tv-power-off",
                          component: (
                              <TurnOff
                                  name={name}
                                  reference={ref}
                                  refetch={refetch}
                                  roomNumber={roomNumber}
                                  location={roomTVName}
                              />
                          ),
                      },
                      ...hideStatus,
                      ...reload,
                      ...commonTVActions,
                  ],
                  [DEVICE_STATUS.offline.label]: [...hideStatus, ...commonTVActions],
                  [DEVICE_STATUS.hiddenStatus.value]: [...showStatus, ...commonTVActions],
                  [DEVICE_STATUS.standby.value]: [
                      {
                          id: "turn-on",
                          label: "tv-power-on",
                          component: (
                              <TurnON
                                  name={name}
                                  reference={ref}
                                  refetch={refetch}
                                  roomNumber={roomNumber}
                                  location={roomTVName}
                              />
                          ),
                      },
                      ...reload,
                      ...hideStatus,
                      ...commonTVActions,
                  ],
                  [DEVICE_STATUS.unassigned.value]: [...assignGGTV, ...unassignedDevices],
              }
            : {
                  [DEVICE_STATUS.online.label]: [
                      ...commonActions,
                      ...desauthorize,
                      ...reload,
                      ...restartDevice,
                      ...hideStatus,
                  ],
                  [DEVICE_STATUS.offline.label]: [...commonActions, ...hideStatus, ...desauthorize],
                  [DEVICE_STATUS.hiddenStatus.value]: [...showStatus, ...commonActions, ...desauthorize],
                  [DEVICE_STATUS.unauthorized.label]: [
                      superUser && {
                          id: "authorise-and-assign",
                          label: "authorise-and-assign",
                          component: (
                              <AuthorizeAndAssign
                                  name={name}
                                  type={type}
                                  mac={mac}
                                  model={model}
                                  reference={ref}
                                  refetch={refetch}
                                  deviceType={DEVICE_TYPES.philips.value}
                                  deviceFunction={deviceFunction}
                              />
                          ),
                      },
                      ...unauthorizedDevices,
                  ],
                  [DEVICE_STATUS.unassigned.label]: [...assignGGTV, ...unassignedDevices],
                  [DEVICE_STATUS.unavailable.value]: [...commonActions, ...desauthorize],
              };

    const googleTVStatusActions = {
        [DEVICE_STATUS.online.label]: onlineGoogleTVActions,
        [DEVICE_STATUS.standby.value]: onlineGoogleTVActions,
        [DEVICE_STATUS.lowSignal.value]: onlineGoogleTVActions,
        [DEVICE_STATUS.offline.label]: [...commonActionsGGTV, ...hideStatus],
        [DEVICE_STATUS.hiddenStatus.value]: [...commonActionsGGTV, ...showStatus],
        [DEVICE_STATUS.unavailable.value]: [
            ...commonActionsGGTV,
            ...([DEVICE_FUNCTIONS.cast.value].includes(deviceFunction) ? reload : []),
        ],
        [DEVICE_STATUS.unauthorized.label]: [
            superUser && {
                id: "authorise-and-assign",
                label: "authorise-and-assign",
                component: (
                    <AuthorizeAndAssign
                        name={name}
                        type={type}
                        mac={mac}
                        model={model}
                        reference={ref}
                        refetch={refetch}
                        deviceType={DEVICE_TYPES.googleTV.value}
                        deviceFunction={deviceFunction}
                    />
                ),
            },
            ...unauthorizedDevices,
        ],
        [DEVICE_STATUS.unassigned.label]: [...assignGGTV, ...unassignedDevices],
    };

    const chromecastActionsForStatus = chromecastStatusActions[warning?.info] || [];
    if (type === DEVICE_TYPES.chromecast.value) {
        chromecastActionsForStatus.forEach((action) => {
            if (!action) return;
            const { id, label, component } = action;
            addAction(id, label, component);
        });
    }

    const tvActionsForStatus = tvStatusActions[warning?.info] || [];
    if (type !== DEVICE_TYPES.googleTV.value && type !== DEVICE_TYPES.chromecast.value) {
        tvActionsForStatus.forEach((action) => {
            if (!action) return;
            const { id, label, component } = action;
            addAction(id, label, component);
        });
    }

    const googleTVActionsForStatus = googleTVStatusActions[warning?.info] || [];
    if (type === DEVICE_TYPES.googleTV.value) {
        googleTVActionsForStatus.forEach((action) => {
            if (!action) return;
            const { id, label, component, dangerousHTML } = action;
            addAction(id, label, component, dangerousHTML);
        });
    }

    return actions;
};

export const wait = (refetch, ms) => setTimeout(refetch, ms);
